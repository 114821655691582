@import "../../constants.scss";

.day-card-wrapper {
    width: 300px;
    border-radius: 10px;
    margin: 20px 20px;
    border: none;
    text-align: center;
    color: $card-font-color-light;
    background-color: $inactive-card-color-light;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.114);
    @media (prefers-color-scheme: dark) {
        color: $card-font-color-dark;
        background-color: $inactive-card-color-dark;
        box-shadow: 2px 2px 5px rgb(27, 27, 27);
    }

    &:not(.today) img {
        filter: brightness(0.6);
    }

    &.today {
        background-color: $active-card-color-light;
        @media (prefers-color-scheme: dark) {
            background-color: $active-card-color-dark;
        }
    }

    .day-image,
    .day-image-wrapper {
        max-width: 100%;
        border-radius: 10px 10px 0 0;
        max-height: 300px;
    }

    .day-image-wrapper {
        display: flex;
        height: 300px;
        align-items: center;
        justify-content: center;
    }

    .content-wrapper {
        padding: 30px 10px;
    }

    .day-card-header {
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        margin: 0 50px;
        border-bottom-color: $card-font-color-light;
        @media (prefers-color-scheme: dark) {
            border-bottom-color: $card-font-color-dark;
        }
    }

    .meal-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .meal-item-wrapper {
        padding-top: 30px;
    }

    .meal-header {
        font-size: 20px;
        font-weight: 500;
    }

    .fav {
        font-weight: 500;
        color: gold;
        text-shadow: 1px 1px 3px darkgoldenrod;
    }
}
