$header-background-color-light: #333333;
$header-font-color-light: #f0f0f0;
$background-color-light: #f0f0f0;
$inactive-card-color-light: #e8e8e8;
$active-card-color-light: #ffffff;
$card-font-color-light: #252525;

$header-background-color-dark: #3f3e3e;
$header-font-color-dark: #f5f5f5;
$background-color-dark: #333333;
$inactive-card-color-dark: #3f3e3e;
$active-card-color-dark: #252525;
$card-font-color-dark: #f9f9f9;
