@import "./constants.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;

  background-color: $background-color-light;
  color: $header-font-color-light;

  @media (prefers-color-scheme: dark) {
    background-color: $background-color-dark;
    color: $header-font-color-dark;
  }

  &:after {
    content: " ";
    display: block;
    top: 0;
    position: absolute;
    z-index: -100;
    width: 100%;
    min-height: 440px;
    height: 50vh;
    max-height: calc(20vh + 250px);
    background-color: $header-background-color-light;
    @media (prefers-color-scheme: dark) {
      background-color: $header-background-color-dark;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
