@import "../../constants.scss";

.header-component {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  h1 {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 0 40px 20px;
    border-bottom-color: $header-font-color-light;
    @media (prefers-color-scheme: dark) {
      border-bottom-color: $header-font-color-dark;
    }
  }
  h2 {
    font-weight: normal;
  }
}
