body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f0f0f0;
  background-color: #f0f0f0;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 30px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #f5f5f5;
    background-color: #333;
  }
}

body:after {
  content: " ";
  z-index: -100;
  width: 100%;
  min-height: 440px;
  height: 50vh;
  max-height: calc(20vh + 250px);
  background-color: #333;
  display: block;
  position: absolute;
  top: 0;
}

@media (prefers-color-scheme: dark) {
  body:after {
    background-color: #3f3e3e;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.day-card-wrapper {
  width: 300px;
  text-align: center;
  color: #252525;
  background-color: #e8e8e8;
  border: none;
  border-radius: 10px;
  margin: 20px;
  box-shadow: 2px 2px 3px #0000001d;
}

@media (prefers-color-scheme: dark) {
  .day-card-wrapper {
    color: #f9f9f9;
    background-color: #3f3e3e;
    box-shadow: 2px 2px 5px #1b1b1b;
  }
}

.day-card-wrapper:not(.today) img {
  filter: brightness(.6);
}

.day-card-wrapper.today {
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  .day-card-wrapper.today {
    background-color: #252525;
  }
}

.day-card-wrapper .day-image, .day-card-wrapper .day-image-wrapper {
  max-width: 100%;
  max-height: 300px;
  border-radius: 10px 10px 0 0;
}

.day-card-wrapper .day-image-wrapper {
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.day-card-wrapper .content-wrapper {
  padding: 30px 10px;
}

.day-card-wrapper .day-card-header {
  text-transform: uppercase;
  border-bottom: 2px solid #252525;
  margin: 0 50px;
  font-size: 28px;
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  .day-card-wrapper .day-card-header {
    border-bottom-color: #f9f9f9;
  }
}

.day-card-wrapper .meal-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.day-card-wrapper .meal-item-wrapper {
  padding-top: 30px;
}

.day-card-wrapper .meal-header {
  font-size: 20px;
  font-weight: 500;
}

.day-card-wrapper .fav {
  color: gold;
  text-shadow: 1px 1px 3px #b8860b;
  font-weight: 500;
}

.header-component {
  min-height: 20vh;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  display: flex;
}

.header-component h1 {
  border-bottom: 2px solid #f0f0f0;
  padding: 0 40px 20px;
}

@media (prefers-color-scheme: dark) {
  .header-component h1 {
    border-bottom-color: #f5f5f5;
  }
}

.header-component h2 {
  font-weight: normal;
}

.day-list-component {
  flex-wrap: wrap;
  place-content: center;
  align-items: stretch;
  display: flex;
}

/*# sourceMappingURL=index.5b4f093d.css.map */
